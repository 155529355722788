import { Controller } from '@hotwired/stimulus';
import EditorJS from '@editorjs/editorjs';

import Checklist from '@editorjs/checklist';
import Header from '@editorjs/header';
import ImageTool from '@editorjs/image';
import List from '@editorjs/list';
import Paragraph from '@editorjs/paragraph';

// Connects to data-controller="editor"
export default class extends Controller {
  static targets = ['editor_content'];

  connect() {
    const initialContent = this.getInitialContent();

    const tools = {
      checklist: {
        class: Checklist,
        inlineToolbar: true,
      },
      header: {
        class: Header,
        config: {
          levels: [1, 2, 3],
        },
        inlineToolbar: true,
      },
      list: {
        class: List,
        inlineToolbar: true,
      },
      paragraph: {
        class: Paragraph,
        config: {
          inlineToolbar: true,
        }
      }
    };

    if (this.element.dataset.premium === 'true') {
      tools['image'] = {
        class: ImageTool,
        config: {
          endpoints: {
            byFile: '/topics/upload_image'
          },
          additionalRequestHeaders: {
            'X-CSRF-Token': this.csrfToken()
          }
        }
      }
    }


    this.contentEditor = new EditorJS({
      holder: this.editor_contentTarget,
      data: initialContent,
      tools: tools
    });

    this.element.addEventListener('submit', this.saveEditorData.bind(this));
  }

  getInitialContent() {
    const hiddenContentField = document.getElementById('content_hidden');
    if (hiddenContentField && hiddenContentField.value) {
      return JSON.parse(hiddenContentField.value);
    }
    return {};
  }

  async saveEditorData(event) {
    event.preventDefault();

    const outputData = await this.contentEditor.save();
    const topicForm = this.element;

    const hiddenInput = document.getElementById('content_hidden');

    hiddenInput.value = JSON.stringify(outputData);
    topicForm.submit();
  }

  csrfToken() {
    const metaTag = document.querySelector('meta[name="csrf-token"]');
    return metaTag ? metaTag.content : '';
  }
}